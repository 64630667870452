// vendor
import { defineStore } from "pinia";
export const useAuthenticationStore = defineStore("authentication", {
    state: () => ({
        authToken: "",
        currentTimestamp: Date.now(),
        lastActivity: Date.now(),
        sessionInterval: null
    }),
    getters: {
        getAuthToken(state) {
            return state.authToken;
        },
        getLastActivityInMinutes(state) {
            const diffInMinutes = Math.floor(((state.currentTimestamp - state.lastActivity) / 1000) / 60);
            return diffInMinutes < 0 ? 0 : diffInMinutes;
        }
    }
});

// vendor
import { defineStore } from "pinia";
export const useAppStore = defineStore("app", {
    state: () => ({
        appTitle: "MVV Energie Kundenportal",
        isInfoBannerVisible: true,
        isLoading: false
    }),
    getters: {
        getAppTitle(state) {
            return state.appTitle;
        },
        getIsInfoBannerVisible(state) {
            return state.isInfoBannerVisible;
        },
        getIsLoading(state) {
            return state.isLoading;
        }
    }
});

import Vue from "vue";
import VueRouter from "vue-router";
// Store
import { useAuthenticationStore } from "@/store/authentication";
Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        name: "VSplashpage",
        component: () => import(/* webpackChunkName: "VSplashpage" */ "../views/splashpage/Splashpage.vue"),
        meta: {
            layout: "default/Default",
            showNavigation: false
        }
    },
    {
        path: "/account-aktivieren",
        name: "VActivateAccount",
        component: () => import(/* webpackChunkName: "VActivateAccount" */ "../views/activateAccount/ActivateAccount.vue"),
        meta: {
            layout: "default/Default"
        }
    },
    {
        path: "/angebote",
        name: "VOffers",
        component: () => import(/* webpackChunkName: "VOffers" */ "../views/offers/Offers.vue"),
        meta: {
            requireAuth: true,
            layout: "default/Default"
        }
    },
    {
        path: "/angebote/fahrzeugvergleich",
        name: "VVehicleCompare",
        component: () => import(/* webpackChunkName: "VVehicleCompare" */ "../views/vehicleCompare/VehicleCompare.vue"),
        meta: {
            requireAuth: true,
            layout: "default/Default"
        }
    },
    {
        path: "/angebote/:id",
        name: "VOfferDetails",
        component: () => import(/* webpackChunkName: "VOfferDetails" */ "../views/offerDetail/OfferDetail.vue"),
        meta: {
            requireAuth: true,
            layout: "default/Default"
        }
    },
    {
        path: "/angebote/:id/checkout",
        name: "VCheckout",
        component: () => import(/* webpackChunkName: "VCheckout" */ "../views/checkout/Checkout.vue"),
        meta: {
            type: "offer",
            layout: "default/Default",
            requireAuth: true
        }
    },
    {
        path: "/angebote/:id/checkout/confirmation",
        name: "VCheckoutConfirmation",
        component: () => import(/* webpackChunkName: "VCheckoutConfirmation" */ "../views/checkoutConfirmation/CheckoutConfirmation.vue"),
        meta: {
            requireAuth: true,
            layout: "default/Default"
        }
    },
    {
        path: "/profil",
        name: "VProfile",
        component: () => import(/* webpackChunkName: "VProfile" */ "../views/profile/Profile.vue"),
        meta: {
            requireAuth: true,
            layout: "default/Default"
        }
    },
    {
        path: "/kontakt",
        name: "VContact",
        component: () => import(/* webpackChunkName: "VContact" */ "../views/contact/Contact.vue"),
        meta: {
            requireAuth: true,
            layout: "default/Default"
        }
    },
    {
        path: "/leistungen",
        name: "VServices",
        component: () => import(/* webpackChunkName: "VServices" */ "../views/services/Services.vue"),
        meta: {
            requireAuth: true,
            layout: "default/Default"
        }
    },
    {
        path: "/faq",
        name: "VFaq",
        component: () => import(/* webpackChunkName: "VFaq" */ "../views/faq/Faq.vue"),
        meta: {
            requireAuth: true,
            layout: "default/Default"
        }
    },
    {
        path: "/bestellungen",
        name: "VOrders",
        component: () => import(/* webpackChunkName: "VOrders" */ "../views/orders/Orders.vue"),
        meta: {
            requireAuth: true,
            layout: "default/Default"
        }
    },
    {
        path: "/bestellungen/:id",
        name: "VOrderDetails",
        component: () => import(/* webpackChunkName: "VOrderDetails" */ "../views/orderDetails/OrderDetails.vue"),
        meta: {
            requireAuth: true,
            layout: "default/Default"
        }
    },
    {
        path: "/bestellungen/:id/versteuerungsangaben",
        name: "VTaxationUpdate",
        component: () => import(/* webpackChunkName: "VTaxationUpdate" */ "../views/checkout/Checkout.vue"),
        meta: {
            type: "order",
            layout: "default/Default",
            requireAuth: true
        }
    },
    {
        path: "/sitzung-abgelaufen",
        name: "VSessionExpired",
        component: () => import(/* webpackChunkName: "VSessionExpired" */ "../views/sessionExpired/SessionExpired.vue"),
        meta: {
            layout: "default/Default",
            showNavigation: false
        }
    },
    {
        path: "/neues-passwort-vergeben/:token",
        name: "VResetPassword",
        component: () => import(/* webpackChunkName: "VResetPassword" */ "../views/resetPassword/ResetPassword.vue"),
        meta: {
            layout: "default/Default",
            showNavigation: false
        }
    },
    {
        path: "*",
        name: "VError",
        component: () => import(/* webpackChunkName: "VError" */ "../views/error/Error.vue"),
        meta: {
            layout: "default/Default",
            showNavigation: false
        }
    }
];
const router = new VueRouter({
    base: process.env.BASE_URL,
    mode: "history",
    routes
});
router.beforeResolve((to, from, next) => {
    var _a;
    // Clean up local storage on splashscreen
    if (to.name === "VSplashpage") {
        localStorage.clear();
    }
    // Update activity
    useAuthenticationStore().lastActivity = Date.now();
    // Redirect to login for not logged in users and auth required
    if (((_a = to === null || to === void 0 ? void 0 : to.meta) === null || _a === void 0 ? void 0 : _a.requireAuth) && !useAuthenticationStore().authToken) {
        return next("/");
    }
    return next();
});
export default router;

// vendor
import { datadogRum } from "@datadog/browser-rum";
import { defineStore } from "pinia";
// Config
// @ts-ignore
import Config from "@/config/config";
// Contentful API (MA)
import { MaContentfulApi } from "@meinauto-gmbh/services";
const ContentfulApi = new MaContentfulApi({
    accessToken: Config.value("VUE_APP_CONTENTFUL_ACCESS_TOKEN"),
    host: Config.value("VUE_APP_CONTENTFUL_HOST"),
    space: Config.value("VUE_APP_CONTENTFUL_SPACE_ID")
});
function findContentByEntryId(rootNode, entryId) {
    return rootNode === null || rootNode === void 0 ? void 0 : rootNode.find((item) => item.sys.id === entryId);
}
export const useContentStore = defineStore("content", {
    state: () => ({
        content: null
    }),
    getters: {
        getContent(state) {
            return state.content;
        },
        getSubContentByEntryId() {
            // eslint-disable-next-line
            return (rootNode, entryId) => {
                var _a;
                return findContentByEntryId((_a = this.content) === null || _a === void 0 ? void 0 : _a[rootNode], entryId);
            };
        },
        getErrorMessages(state) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11;
            return {
                accountConfirmation: {
                    alreadyConfirmed: (_c = (_b = findContentByEntryId((_a = state.content) === null || _a === void 0 ? void 0 : _a.errorMessages, "1G7PiidqiRIFRi2Rh6Np2E")) === null || _b === void 0 ? void 0 : _b.fields) === null || _c === void 0 ? void 0 : _c.shortText
                },
                blockedOrder: {
                    activeOrder: (_f = (_e = findContentByEntryId((_d = state.content) === null || _d === void 0 ? void 0 : _d.errorMessages, "1dOrhRrOnQtyNT1BzgOHRa")) === null || _e === void 0 ? void 0 : _e.fields) === null || _f === void 0 ? void 0 : _f.shortText,
                    noWhitelisting: (_j = (_h = findContentByEntryId((_g = state.content) === null || _g === void 0 ? void 0 : _g.errorMessages, "RsvMsm7v1ZIyn1sXr8Auk")) === null || _h === void 0 ? void 0 : _h.fields) === null || _j === void 0 ? void 0 : _j.shortText
                },
                generic: {
                    default: (_m = (_l = findContentByEntryId((_k = state.content) === null || _k === void 0 ? void 0 : _k.errorMessages, "3HCP3ZwJmnkATEo1vTmaLa")) === null || _l === void 0 ? void 0 : _l.fields) === null || _m === void 0 ? void 0 : _m.shortText,
                    pageNotFound: (_q = (_p = findContentByEntryId((_o = state.content) === null || _o === void 0 ? void 0 : _o.errorMessages, "7p0kPQuEwCg2OJ3JziH4Sy")) === null || _p === void 0 ? void 0 : _p.fields) === null || _q === void 0 ? void 0 : _q.shortText
                },
                login: {
                    accountNotFound: (_t = (_s = findContentByEntryId((_r = state.content) === null || _r === void 0 ? void 0 : _r.errorMessages, "UycMQwCBAQnkvZHiFJMOo")) === null || _s === void 0 ? void 0 : _s.fields) === null || _t === void 0 ? void 0 : _t.shortText,
                    badCredentials: (_w = (_v = findContentByEntryId((_u = state.content) === null || _u === void 0 ? void 0 : _u.errorMessages, "2AV4PXwZqO4tr3gJmWHRIl")) === null || _v === void 0 ? void 0 : _v.fields) === null || _w === void 0 ? void 0 : _w.shortText
                },
                resetPassword: {
                    tokenExpired: (_z = (_y = findContentByEntryId((_x = state.content) === null || _x === void 0 ? void 0 : _x.errorMessages, "5pcEInJbUan9JHEVc0YouR")) === null || _y === void 0 ? void 0 : _y.fields) === null || _z === void 0 ? void 0 : _z.shortText,
                    tokenNotFound: (_2 = (_1 = findContentByEntryId((_0 = state.content) === null || _0 === void 0 ? void 0 : _0.errorMessages, "6dTp3gDrSowVQBym7f3UhK")) === null || _1 === void 0 ? void 0 : _1.fields) === null || _2 === void 0 ? void 0 : _2.shortText,
                    wrongOldPassword: (_5 = (_4 = findContentByEntryId((_3 = state.content) === null || _3 === void 0 ? void 0 : _3.errorMessages, "21PXZGcbeQalDy5AN2XQon")) === null || _4 === void 0 ? void 0 : _4.fields) === null || _5 === void 0 ? void 0 : _5.shortText
                },
                salaryCalculator: {
                    default: (_8 = (_7 = findContentByEntryId((_6 = state.content) === null || _6 === void 0 ? void 0 : _6.errorMessages, "66diu2BTR1lVGtefcY6dX5")) === null || _7 === void 0 ? void 0 : _7.fields) === null || _8 === void 0 ? void 0 : _8.shortText
                },
                vehicleCompare: {
                    maxVehicles: (_11 = (_10 = findContentByEntryId((_9 = state.content) === null || _9 === void 0 ? void 0 : _9.errorMessages, "7zV3GjyAslq8klraRurvry")) === null || _10 === void 0 ? void 0 : _10.fields) === null || _11 === void 0 ? void 0 : _11.shortText
                }
            };
        },
        getInfoBanner(state) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
            return {
                content: (_c = (_b = (_a = state.content) === null || _a === void 0 ? void 0 : _a.infoBannerWrapper[0]) === null || _b === void 0 ? void 0 : _b.fields) === null || _c === void 0 ? void 0 : _c.longText,
                showOnLogin: (_g = (_f = (_e = (_d = state.content) === null || _d === void 0 ? void 0 : _d.infoBannerWrapper[0]) === null || _e === void 0 ? void 0 : _e.metadata) === null || _f === void 0 ? void 0 : _f.tags) === null || _g === void 0 ? void 0 : _g.some((elt) => { var _a; return ((_a = elt === null || elt === void 0 ? void 0 : elt.sys) === null || _a === void 0 ? void 0 : _a.id) === "showOnLogin"; }),
                showOnOffer: (_l = (_k = (_j = (_h = state.content) === null || _h === void 0 ? void 0 : _h.infoBannerWrapper[0]) === null || _j === void 0 ? void 0 : _j.metadata) === null || _k === void 0 ? void 0 : _k.tags) === null || _l === void 0 ? void 0 : _l.some((elt) => { var _a; return ((_a = elt === null || elt === void 0 ? void 0 : elt.sys) === null || _a === void 0 ? void 0 : _a.id) === "showOnOffer"; })
            };
        },
        getSuccessMessages(state) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            return {
                confirmedSuccessful: (_c = (_b = findContentByEntryId((_a = state.content) === null || _a === void 0 ? void 0 : _a.successMessages, "4bs5CEUWnT51DNKuzLzWZ8")) === null || _b === void 0 ? void 0 : _b.fields) === null || _c === void 0 ? void 0 : _c.shortText,
                confirmationMailSendAfterExpiredToken: (_f = (_e = findContentByEntryId((_d = state.content) === null || _d === void 0 ? void 0 : _d.successMessages, "1rtUjCHme6TenJXTdw56iK")) === null || _e === void 0 ? void 0 : _e.fields) === null || _f === void 0 ? void 0 : _f.shortText,
                updatesSuccessful: (_j = (_h = findContentByEntryId((_g = state.content) === null || _g === void 0 ? void 0 : _g.successMessages, "7EeNV8XsYt0RanJucxrwAn")) === null || _h === void 0 ? void 0 : _h.fields) === null || _j === void 0 ? void 0 : _j.shortText
            };
        },
        getValidationErrorMessage(state) {
            return (errors) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                if (errors.length === 0)
                    return "";
                switch (errors[errors.length - 1].$validator) {
                    case "email":
                        return (_c = (_b = findContentByEntryId((_a = state.content) === null || _a === void 0 ? void 0 : _a.errorMessages, "5kCfXFG5FpG9nxvyxFnS3D")) === null || _b === void 0 ? void 0 : _b.fields) === null || _c === void 0 ? void 0 : _c.shortText;
                    case "passwordSyntax":
                        return (_f = (_e = findContentByEntryId((_d = state.content) === null || _d === void 0 ? void 0 : _d.errorMessages, "6WmrhLe7YTcKeI8KDreYAm")) === null || _e === void 0 ? void 0 : _e.fields) === null || _f === void 0 ? void 0 : _f.shortText;
                    case "required":
                        return (_j = (_h = findContentByEntryId((_g = state.content) === null || _g === void 0 ? void 0 : _g.errorMessages, "7aY1qePTiq8hPI0tUYAvlS")) === null || _h === void 0 ? void 0 : _h.fields) === null || _j === void 0 ? void 0 : _j.shortText;
                    case "sameAs":
                        return (_m = (_l = findContentByEntryId((_k = state.content) === null || _k === void 0 ? void 0 : _k.errorMessages, "69Czt5OH1JXXUOhVyVGK71")) === null || _l === void 0 ? void 0 : _l.fields) === null || _m === void 0 ? void 0 : _m.shortText;
                    default:
                        return "";
                }
            };
        }
    },
    actions: {
        /**
         * @description Fetch contentful content
         * @return {void}
         */
        async fetchContent() {
            return ContentfulApi
                .byEntryId("6sKix46xESyTEqx2xwmb1o")
                .getClient()
                .fetch()
                .then((res) => {
                var _a;
                if (((_a = res === null || res === void 0 ? void 0 : res.items) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    // @ts-ignore
                    this.content = res.items[0].fields;
                    return;
                }
                throw res;
            })
                .catch((e) => {
                var _a, _b;
                // Log Error
                datadogRum.addError("Error fetching content from contentful", {
                    data: JSON.stringify((_a = e.response) === null || _a === void 0 ? void 0 : _a.data),
                    status: (_b = e.response) === null || _b === void 0 ? void 0 : _b.status
                });
            });
        }
    }
});
